exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-betcheck-about-us-index-tsx": () => import("./../../../src/pages/about/betcheck/about_us/index.tsx" /* webpackChunkName: "component---src-pages-about-betcheck-about-us-index-tsx" */),
  "component---src-pages-about-betcheck-career-index-tsx": () => import("./../../../src/pages/about/betcheck/career/index.tsx" /* webpackChunkName: "component---src-pages-about-betcheck-career-index-tsx" */),
  "component---src-pages-about-betcheck-company-structure-index-tsx": () => import("./../../../src/pages/about/betcheck/company_structure/index.tsx" /* webpackChunkName: "component---src-pages-about-betcheck-company-structure-index-tsx" */),
  "component---src-pages-about-betcheck-data-security-policy-index-tsx": () => import("./../../../src/pages/about/betcheck/data_security_policy/index.tsx" /* webpackChunkName: "component---src-pages-about-betcheck-data-security-policy-index-tsx" */),
  "component---src-pages-about-betcheck-imprint-index-tsx": () => import("./../../../src/pages/about/betcheck/imprint/index.tsx" /* webpackChunkName: "component---src-pages-about-betcheck-imprint-index-tsx" */),
  "component---src-pages-about-betcheck-index-tsx": () => import("./../../../src/pages/about/betcheck/index.tsx" /* webpackChunkName: "component---src-pages-about-betcheck-index-tsx" */),
  "component---src-pages-about-betcheck-responsibility-index-tsx": () => import("./../../../src/pages/about/betcheck/responsibility/index.tsx" /* webpackChunkName: "component---src-pages-about-betcheck-responsibility-index-tsx" */),
  "component---src-pages-about-betcheck-terms-conditions-index-tsx": () => import("./../../../src/pages/about/betcheck/terms_conditions/index.tsx" /* webpackChunkName: "component---src-pages-about-betcheck-terms-conditions-index-tsx" */),
  "component---src-pages-about-info-become-player-index-tsx": () => import("./../../../src/pages/about/info/become_player/index.tsx" /* webpackChunkName: "component---src-pages-about-info-become-player-index-tsx" */),
  "component---src-pages-about-info-contact-index-tsx": () => import("./../../../src/pages/about/info/contact/index.tsx" /* webpackChunkName: "component---src-pages-about-info-contact-index-tsx" */),
  "component---src-pages-about-info-gdpr-index-tsx": () => import("./../../../src/pages/about/info/GDPR/index.tsx" /* webpackChunkName: "component---src-pages-about-info-gdpr-index-tsx" */),
  "component---src-pages-about-info-protection-of-minors-index-tsx": () => import("./../../../src/pages/about/info/protection_of_minors/index.tsx" /* webpackChunkName: "component---src-pages-about-info-protection-of-minors-index-tsx" */),
  "component---src-pages-about-info-responsibility-index-tsx": () => import("./../../../src/pages/about/info/responsibility/index.tsx" /* webpackChunkName: "component---src-pages-about-info-responsibility-index-tsx" */),
  "component---src-pages-about-info-terms-conditions-index-tsx": () => import("./../../../src/pages/about/info/terms_conditions/index.tsx" /* webpackChunkName: "component---src-pages-about-info-terms-conditions-index-tsx" */),
  "component---src-pages-about-programs-become-affiliate-partner-index-tsx": () => import("./../../../src/pages/about/programs/become_affiliate_partner/index.tsx" /* webpackChunkName: "component---src-pages-about-programs-become-affiliate-partner-index-tsx" */),
  "component---src-pages-about-programs-become-partner-index-tsx": () => import("./../../../src/pages/about/programs/become_partner/index.tsx" /* webpackChunkName: "component---src-pages-about-programs-become-partner-index-tsx" */),
  "component---src-pages-about-tipgame-about-us-index-tsx": () => import("./../../../src/pages/about/tipgame/about_us/index.tsx" /* webpackChunkName: "component---src-pages-about-tipgame-about-us-index-tsx" */),
  "component---src-pages-about-tipgame-career-index-tsx": () => import("./../../../src/pages/about/tipgame/career/index.tsx" /* webpackChunkName: "component---src-pages-about-tipgame-career-index-tsx" */),
  "component---src-pages-about-tipgame-company-structure-index-tsx": () => import("./../../../src/pages/about/tipgame/company_structure/index.tsx" /* webpackChunkName: "component---src-pages-about-tipgame-company-structure-index-tsx" */),
  "component---src-pages-about-tipgame-data-security-policy-index-tsx": () => import("./../../../src/pages/about/tipgame/data_security_policy/index.tsx" /* webpackChunkName: "component---src-pages-about-tipgame-data-security-policy-index-tsx" */),
  "component---src-pages-about-tipgame-imprint-index-tsx": () => import("./../../../src/pages/about/tipgame/imprint/index.tsx" /* webpackChunkName: "component---src-pages-about-tipgame-imprint-index-tsx" */),
  "component---src-pages-about-tipgame-index-tsx": () => import("./../../../src/pages/about/tipgame/index.tsx" /* webpackChunkName: "component---src-pages-about-tipgame-index-tsx" */),
  "component---src-pages-about-tipgame-responsibility-index-tsx": () => import("./../../../src/pages/about/tipgame/responsibility/index.tsx" /* webpackChunkName: "component---src-pages-about-tipgame-responsibility-index-tsx" */),
  "component---src-pages-about-tipgame-terms-conditions-index-tsx": () => import("./../../../src/pages/about/tipgame/terms_conditions/index.tsx" /* webpackChunkName: "component---src-pages-about-tipgame-terms-conditions-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

